.PicMeBanner {
  /*  margin-left: 32px;*/
  margin-left: 0px;
  display: flex;

  @media only screen and (min-width: 800px) {
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
}

@media only screen and (max-width: 800px) {
  .PicMeBanner {
    /*    margin-top: 60px;*/
    margin-left: 0px;
    text-align: center;
  }
}
.banner-header {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  font-size: 32px;
  font-weight: bold;
  padding-top: 32px;
  padding-bottom: 32px;
  @media only screen and (max-width: 800px) {
    justify-content: center;
  }
}

.banner-header-word {
  height: 52px;
  display: flex;
  align-items: center;
}

.banner-text {
  padding-bottom: 24px;
  font-size: 24px;
}

.banner-button {
  padding-bottom: 80px;
  font-size: 24px;
}
.picme-banner-logo {
  height: 120px;
}

.picme-banner-image {
  max-height: 70vh;
  min-height: 70vh;
  margin-right: 56px;

  max-width: 90%;
  /*  height: 100%;*/
  flex: 1;
}
