/*7ff0ffc4*/
/*bdb6ff*/
/*/2ddcff*/
:root {
  --purple: #9456f6;
  --purple-dark: #7f52ff;
  --purple-light: #d0cdfa;
  --purple-lightest: #f3f0fc;

  --green: #54c295;
  /*  --red: #f7a491;*/
  --red: #fc716e;

  --blue-light: #8dc4f7;

  --grey: #8e8e8e;

  --input-border-radius: 6px;
  --border-radius: 14px;
  --pane--border-radius: 24px;

  --primary-color: #fffff000;
  --button-color: #9456f6;
  /*  --primary-color: #42d954;*/
  --secondary-color: blue;
  --pane-backgound-color: #ffffff;
  --grey-pane-backgound-color: #f2f2f2;
  --whatsapp-color: #24d366;

  --grey-light-color: #f8f8f8;

  --font-size-xxl: 32px;
  --font-size-xl: 25px;
  --font-size-l: 20px;
  --font-size-m: 16px;
  --font-size-s: 14px;

  @media only screen and (max-width: 800px) {
    --font-size-xxl: 25px;
    --font-size-xl: 20px;
    --font-size-l: 16px;
    --font-size-m: 14px;
    --font-size-s: 12px;
  }

  --page-h-padding: 92px;
  --page-h-padding-big: 240px;

  --page-h-padding-mobile: 16px;
  --page-h-padding-big-mobile: 16px;

  --event-box-max-width: 520px;

  --primary-backgound-color: #fff9f2;

  --image-box-height: 440px;
  --image-box-margin: 20px;
  --image-height: 250px;
  --image-box-footer-height: 70px;
}

@font-face {
  font-family: "GveretLevin";
  src:
    local("GveretLevin"),
    url(./fonts/GveretLevin-Regular.otf) format("opentype");
}

@font-face {
  font-family: "LieblingProMedium";
  src:
    local("LieblingProMedium"),
    url(./fonts/LieblingProMedium.otf) format("opentype");
}

@font-face {
  font-family: "LieblingProBlack";
  src:
    local("LieblingProBlack"),
    url(./fonts/LieblingProBlack.otf) format("opentype");
}

@font-face {
  font-family: "LieblingProRegular";
  src:
    local("LieblingProRegular"),
    url(./fonts/LieblingProRegular.otf) format("opentype");
}

html,
body,
#root {
  margin: 0;
  height: 100%;
  background-color: var(--primary-color);
  /*  height: 100%;*/
  /*  width: 100%;*/
  direction: rtl;
  font-size: var(--font-size-m);
  font-family: LieblingProMedium;
  background-color: var(--primary-backgound-color);
}

.gevert-levin-text {
  font-family: GveretLevin;
}

.blue-text {
  color: var(--blue-light);
}

.green-text {
  color: var(--green);
}

.red-text {
  color: var(--red);
}

.danger-red-text {
  color: red;
}

.purple-text {
  color: var(--purple);
}

.white-text {
  color: white;
}

.purple-dark-text {
  color: var(--purple-dark);
}

.grey-text {
  color: var(--grey);
}

.ltr {
  direction: ltr;
}

.font-xxl {
  font-size: var(--font-size-xxl);
}

.font-xl {
  font-size: var(--font-size-xl);
}

.font-l {
  font-size: var(--font-size-l);
}

.font-m {
  font-size: var(--font-size-m);
}

.font-s {
  font-size: var(--font-size-s);
}

.Link {
  text-decoration: none;
  color: black;
}

a {
  text-decoration: none;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

.overflow-y-auto {
  overflow-y: auto;
}

.Page {
  height: 100%;
  width: 100%;
}

.page-content {
  min-height: 95vh;
}

.bt {
  border-top: 1px solid var(--purple-dark);
}

.bb {
  border-bottom: 1px solid var(--purple-dark);
}

.page-section-content {
  @media only screen and (min-width: 800px) {
    min-height: 88vh;
  }
}

.page-section-padding {
  padding: 32px var(--page-h-padding);

  @media only screen and (max-width: 800px) {
    padding: 32px var(--page-h-padding-mobile);
  }
}

.page-section-big-padding {
  padding: 32px var(--page-h-padding-big);
}

@media only screen and (max-width: 800px) {
  .page-section-big-padding {
    padding: 16px;
  }
}

.disable-select {
  user-select: none;
}

.page-section-diveder {
  margin-top: 44px;
  margin-bottom: 44px;
  height: 1px;
  width: 100%;
  background-color: grey;
}

.section-title {
  min-height: 12vh;
  margin-top: 40px;
  font-size: var(--font-size-xl);
}

.navbar-item {
  /*  padding: 0 24px;*/
  height: 100%;
  text-decoration: none; /* no underline */
  color: black !important;
  font-size: 18px;
  display: flex;
  align-items: center;
}

.navbar-help-item {
  padding-left: 100px;
}

.navbar-logo {
  height: 60%;
  margin-left: var(--page-h-padding);
  display: flex;
  align-items: center;

  @media only screen and (max-width: 800px) {
    margin-left: 16px;
  }
}

.icon-xlarge {
  width: 40px;
  height: 40px;
}

.icon-large {
  width: 32px;
  height: 32px;
}

.icon {
  width: 24px;
  height: 24px;
}

.icon-pt {
  padding-top: 2px;
}

.icon-small {
  width: 16px;
  height: 16px;
}

.menu-icon {
  max-height: 20px;
}

.navbar-profile-button {
  height: 50%;
  margin-right: var(--page-h-padding);
}

@media only screen and (max-width: 800px) {
  .navbar-profile-button {
    margin-right: 16px;
  }
}

.navbar-profile-icon {
  width: 420px;
  height: 50%;
  margin-right: var(--page-h-padding);
}

@media only screen and (max-width: 800px) {
  .navbar-profile-icon {
    width: 240px;
    margin-right: 16px;
  }
}

.navbar-logo-icon {
  height: 100%;
}

.Navbar {
  z-index: 1;
  background-color: var(--purple-lightest);
  font-size: 14px;
  font-weight: bold;
  min-height: 80px;
  align-items: center;
  justify-content: space-between;
  display: flex;
  height: 100px;

  @media only screen and (max-width: 800px) {
    height: 20px;
  }
}

.form-error {
  height: 24px;
  color: red;
  font-size: var(--font-size-s);
}

.form-success {
  height: 24px;
  font-weight: bold;
  color: var(--purple);
  font-size: var(--font-size-s);
}

.nav-bar-icon {
  font-size: 24px;
}

d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.d-flex-mobile {
  display: flex;
}

@media only screen and (max-width: 800px) {
  .d-flex-mobile {
    display: flex;
    flex-direction: column;
  }
}

.d-flex-column {
  display: flex;
  flex-direction: column;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-start {
  align-items: flex-start;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-end {
  justify-content: flex-end;
}

.pt24 {
  padding-top: 24px;
}

.pt32 {
  padding-top: 32px;
}

.pt40 {
  padding-top: 40px;
}

.pt44 {
  padding-top: 44px;
}

.pb4 {
  padding-bottom: 4px;
}

.pb8 {
  padding-bottom: 8px;
}

.mr8 {
  margin-right: 8px;
}

.pb12 {
  padding-bottom: 12px;
}

.pb16 {
  padding-bottom: 16px;
}

.pb24 {
  padding-bottom: 24px;
}

.pb32 {
  padding-bottom: 32px;
}

.pb40 {
  padding-bottom: 40px;
}

.pt12 {
  padding-top: 12px;
}

.pt16 {
  padding-top: 16px;
}

.mt40 {
  margin-top: 40px;
}

.mb80 {
  margin-bottom: 80px;
}

.mb40 {
  margin-bottom: 40px;
}

.mb24 {
  margin-bottom: 24px;
}

.mb12 {
  margin-bottom: 12px;
}

.mb8 {
  margin-bottom: 8px;
}

.mt12 {
  margin-top: 12px;
}

.mt24 {
  margin-top: 24px;
}

.mh24 {
  margin-right: 24px;
  margin-left: 24px;
}

.mv40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.bold {
  font-family: LieblingProBlack;
}

.regular {
  font-family: LieblingProMedium;
}

.thin {
  font-family: LieblingProRegular;
}

.italic {
  font-style: italic;
}

.pr-text {
  padding-right: 5px;
}

.pl-text {
  padding-left: 5px;
}

.ph-text {
  padding-left: 5px;
  padding-right: 5px;
}

.ph12 {
  padding-left: 12px;
  padding-right: 12px;
}

.ph16 {
  padding-left: 16px;
  padding-right: 16px;
}

.ph32 {
  padding-left: 32px;
  padding-right: 32px;
}

.ph4 {
  padding-right: 4px;
  padding-left: 4px;
}

.pv32 {
  padding-top: 32px;
  padding-bottom: 32px;
}

.pv40 {
  padding-top: 92px;
  padding-bottom: 92px;
}

.pr4 {
  padding-right: 4px;
}

.pl4 {
  padding-left: 4px;
}

.pl12 {
  padding-left: 12px;
}

.pl16 {
  padding-left: 16px;
}

.pl24 {
  padding-left: 24px;
}

.pl32 {
  padding-left: 32px;
}

.mr12 {
  margin-right: 12px;
}

.mr24 {
  margin-right: 24px;
}

.ml12 {
  margin-left: 12px;
}

.ml24 {
  margin-left: 24px;
}

.ml40 {
  margin-left: 40px;
}

.ml16 {
  margin-left: 16px;
}

.pr12 {
  padding-right: 12px;
}

.pr24 {
  padding-right: 24px;
}

.label {
  text-align: right;
  padding-right: 12px;
  margin-bottom: 8px;
}

.checkbox {
  width: 24px;
  margin: 0 0 0 8px !important;
}

input {
  /*  width: 200px;*/
  border-radius: var(--input-border-radius);
  padding: 12px;
  font-size: 16px;
  border: 1px solid #d5d5d5;
}

input:focus {
  outline: none !important;
  border: 1px solid var(--secondary-color);
}

textarea {
  border: 1px solid #d5d5d5;
  border-radius: var(--input-border-radius);
  padding: 12px;
  font-size: 16px;
  width: auto;
  height: 150px;
  font-family: inherit;
}

.green-button {
  background-color: var(--green);
}

.danger-button {
  background-color: var(--red);
}

.black-button {
  font-family: LieblingProMedium;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 1px solid black;
  color: black;
}

.picme-button {
  background-color: var(--purple);
  color: white;
  padding-left: 28px;
  padding-right: 28px;
  box-shadow: 0px 8px 18px 1px #9696968f;
}

.purple-dark-button {
  background-color: var(--purple-dark);
  color: white;
  /*  padding-left: 28px;*/
  /*  padding-right: 28px;*/
}

.purple-on-hover:hover {
  color: var(--purple);
}

.mobile-hover-button {
  padding: 24px 44px;
  position: fixed;
  bottom: 24px;
  left: var(--page-h-padding);
  z-index: 2;

  font-size: var(--font-size-xl);
  border-radius: var(--border-radius);
}

@media only screen and (max-width: 800px) {
  .mobile-hover-button {
    border-radius: 0;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}

.inline-button {
  cursor: pointer;
  border: none;
  padding: 0;
  border-radius: 0;
  background-color: transparent;
  color: var(--purple-dark);
  font-size: var(--font-size-m);
  font-weight: normal;
  text-decoration: none;
}

.inline-button:hover {
  color: var(--purple);
}

.primary-button {
  padding: 14px 44px;
  background-color: var(--purple-dark);
  font-size: var(--font-size-xl);
}

.big-button {
  padding: 14px 44px;
  font-size: var(--font-size-xl);
}

.create-event-button {
  padding: 16px 24px;
}

button {
  cursor: pointer;
  border: none;
  padding: 10px 28px;
  border-radius: var(--input-border-radius);
  background-color: var(--purple-dark);
  color: white;
  font-size: var(--font-size-l);
  font-family: LieblingProMedium;
}

button:focus {
  outline: 0;
  box-shadow: none;
}

button:disabled {
  background-color: var(--purple-light);
  cursor: not-allowed;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.font-large {
  font-size: 24px;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-1 {
  flex: 1;
}

.p16 {
  padding: 16px;
}

.p24 {
  padding: 24px;
}

.p32 {
  padding: 32px;
}

.ph12 {
  padding: 0 12px;
}

.ph40 {
  padding: 0 40px;
}

.italic {
  font-style: italic;
}

.border-radius {
  border-radius: var(--border-radius);
}

.login-form {
  /*  box-shadow: 0px 9px 20px 3px #73628a40;*/
  /*  border: 4px solid var(--purple);*/
  /*  border-radius: var(--pane--border-radius);*/
  /*  margin: 400px auto 200px auto;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /*  width: fit-content;*/
  /*  padding: 60px 120px 100px 120px;*/

  /*  min-height: 420px;*/
}

@media only screen and (max-width: 800px) {
  .login-form {
    /*    padding: 32px 32px 32px 32px;*/
    /*    min-height: 0px;*/
    /*    margin: 4px;*/
    /*    margin-top: 40px;*/
    margin-bottom: 24px;
    /*    width: -webkit-fill-available;*/
  }
}

.logo-icon {
  max-height: 100px;
}

.loading-circle {
  visibility: visible;
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 0.2em solid rgba(0, 0, 0, 0.1);
  border-top-color: #ffffff;
  animation: loading-circle-animation 0.6s infinite linear;
}

.loading-circle-hidden {
  visibility: hidden;
  animation: none;
}

@keyframes loading-circle-animation {
  to {
    transform: rotate(360deg);
  }
}

.font-xl {
  font-size: var(--font-size-xl);
}

.font-l {
  font-size: var(--font-size-l);
}

.font-m {
  font-size: var(--font-size-m);
}

.font-s {
  font-size: var(--font-size-s);
}

.divider {
  width: 100%;
  height: 1px;
  background-color: var(--purple-dark);
}

.tooltip-max-width {
  max-width: 200px;
}

.bg-purple-lightest {
  background-color: var(--purple-lightest);
}

.bg-white {
  background-color: white;
}

.hidden {
  visibility: hidden;
}

.pointer {
  cursor: pointer;
}

.small-tooltip {
  max-width: 150px;
}

.height-max-content {
  height: max-content;
}

@keyframes move {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10%);
  }
  100% {
    transform: translateY(0);
  }
}

.moving-images-wrapper {
  width: 100%;
  overflow: hidden;
  height: 450px;
}

.moving-images-content {
  animation: move 10s linear infinite;
}

.remove-button {
  cursor: pointer;
  background-color: white;
  border: 1px solid var(--red);
  color: var(--red);
  padding: 4px 12px;
  border-radius: 12px;
}
