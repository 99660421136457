.UploadImagesWidget {
  min-height: 250px;
  min-width: 250px;

  @media only screen and (max-width: 800px) {
    min-height: 200px;
    min-width: 200px;
  }

  padding: 24px;
  border: 2px dashed var(--purple-light) !important;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.UploadImagesWidget:hover {
  border: 2px dashed var(--purple) !important;
}
