.EventStatusBadge {
  width: fit-content;
  border-radius: 4px;
  color: white;
}

.EventStatusBadge-small {
  padding: 4px 10px;
  font-size: var(--font-size-l);
}
.EventStatusBadge-large {
  font-size: var(--font-size-xl);
  padding: 12px 32px;
}

.EventStatusBadge-future {
  background-color: var(--blue-light);
}

.EventStatusBadge-active {
  background-color: var(--green);
}

.EventStatusBadge-past {
  background-color: var(--red);
}
