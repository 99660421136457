.masonic {
  width: 100%;
}

.masonic-container {
  min-height: 100vh;
  width: 100%;
}

.masonic-card {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  transition: transform 100ms ease-in-out;
  width: 100%;
  min-height: 200px;
  background-color: var(--purple-lightest);
  @media only screen and (min-width: 800px) {
    &:hover {
      transform: scale(1.05);
    }
  }
}
.masonic-opacity {
  opacity: 0.2;
}

.masonic-card-action {
  position: absolute;
  top: 12px;
  left: 12px;
}

.masonic-card-img {
  width: 100%;
  display: block;
  border-radius: var(--border-radius);
}
