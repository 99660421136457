.LoginPackages-section {
  /*  background-color: var(--purple-dark);*/
}
.LoginPackages-item {
  background-color: var(--purple-lightest);
  display: flex;
  flex-direction: column;
  /*  justify-content: space-between;*/
  align-items: center;

  max-width: 400px;
  min-height: 600px;
  text-align: center;

  border-radius: 6px;
  padding: 32px 80px;
  /*  box-shadow: 0px 5px 10px 3px #73628a40;*/
  margin-left: 24px;
}
.LoginPackages-item:last-child {
  margin-left: 0px;
}

@media only screen and (max-width: 800px) {
  .LoginPackages-item {
    margin-left: 0px;
    margin-bottom: 40px;
    padding: 32px;
    min-height: 0;
    max-width: none;
  }
}

.LoginPackages-header {
  color: var(--purple);
  font-size: var(--font-size-xl);
  padding-bottom: 32px;
  font-weight: bold;
}

.LoginPackages-body {
  background-color: var(--purple-light);
  padding: 24px;
  border-radius: 6px;
  /*  flex: 1;*/
  font-size: var(--font-size-xl);
  /*  padding-bottom: 32px;*/
}

.LoginPackages-specialBody {
  /*  flex: 1;*/
  color: var(--purple);
  font-size: var(--font-size-l);
  /*  font-weight: bold;*/
  padding-top: 32px;
  padding-bottom: 32px;
}

.LoginPackages-list {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 32px;
  flex: 1;
  font-size: var(--font-size-l);
}

.LoginPackages-image {
  /*  max-width: 50%;*/
  height: 200px;
  margin-bottom: 40px;
}

.LoginPackages-button-icon {
  width: 32px;
  height: 32px;
  margin: 12px;
  border: 1px solid black;
  border-radius: 12px;
}
/*Make everything pop*/
/* https://polypane.app/css-3d-transform-examples/*/
