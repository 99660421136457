.EventStatusBox {
  /*  height: 160px;*/
  /*  box-shadow: 0px 5px 10px 3px #73628a40;*/
  /*  border-radius: 24px;*/

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  text-align: center;

  /*  padding: 24px;*/

  margin-left: 40px;
  margin-right: 40px;
}
.EventStatusNumber {
  font-size: 65px;
}
.EventStatusBox-firstRow {
  /*  margin-bottom: 80px;*/
}

@media only screen and (min-width: 800px) {
  .EventStatusBox {
    width: 160px;
  }
}

@media only screen and (max-width: 800px) {
  .EventStatusBox {
    margin-bottom: 40px;
    max-width: 100%;

    margin-left: 0px;
    margin-right: 0px;
  }
  .EventStatusBox-firstRow {
    margin-bottom: 0px;
  }
}
