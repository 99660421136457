.SearchResults-row {
  direction: rtl;
  height: calc(var(--image-box-height) - var(--image-box-margin));
  display: grid;

  @media only screen and (max-width: 800px) {
    grid-template-columns: 1fr;
  }
  grid-template-columns: repeat(6, 1fr);

  gap: var(--image-box-margin);
  margin-bottom: var(--image-box-margin);
}

.SearchResultItem-imageBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 12px;
  border: 1px solid var(--purple-dark);
  height: calc(var(--image-box-height) - var(--image-box-margin));
}

.SearchResults {
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  @media only screen and (min-width: 800px) {
    padding: 24px;
    border-radius: 12px;
  }
  border: 1px solid var(--purple-dark);
}

.SearchResultItem {
  padding: 24px;
  border-radius: 24px;
  box-shadow: 0px 5px 10px 3px #73628a40;
  margin-left: 24px;
  margin-bottom: 32px;
}

@media only screen and (max-width: 800px) {
  .SearchResultItem {
    margin-left: 0px;
    width: 100%;
  }
}

.SearchResultItem-selfie {
  background-color: var(--purple-light);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  border-radius: 12px;
  border: 4px solid var(--purple-dark);

  /*  height: var(--image-height);*/
}
.SearchResultItem-footer {
  width: 100%;
  height: var(--image-box-footer-height);
}
.SearchResultItem-footer-selfie {
  height: calc(var(--image-box-footer-height) - 3px);
}
.SearchResultItem-footer-action {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SearchResultItem-selfie-image {
  border-radius: 12px;
  /*  flex: 1;*/
  height: var(--image-height);
  /*  //calc(var(--image-height) - 60px);*/
  /*  width: fit-content;*/
  margin: 12px;
  border: 1px solid var(--purple-dark);
}

.SearchResultItem-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.SearchResultItem-image-wrapper {
  /*  margin: 5px;*/
  height: var(--image-height);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  flex: 1;
}

.SearchResultItem-image {
  border-radius: 12px;
  height: auto;
  width: 100%;
  border: 1px solid var(--purple-dark);
}

.SearchResultItem-image-wrapper-isRemoved {
  opacity: 0.5;
}

.SearchResultItem-actions-remove-wrapper {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  border-top: 1px solid var(--purple-dark);
}
.SearchResultItem-actions-score {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 12px;
  border-left: 1px solid var(--purple-dark);
  height: 100%;
}

.SearchResultItem-actions-remove {
  cursor: pointer;
  margin-right: 4px;
  color: var(--red);
  font-size: var(--font-size-xl);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  flex: 1;
}

.SearchResultItem-actions-revert {
  flex: 1;
  cursor: pointer;
  margin-right: 4px;
  color: blue;
  font-size: var(--font-size-xl);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
}

.SearchResultItem-actions-status {
  position: absolute;
  bottom: 12px;
  right: 12px;
  color: white;
  padding: 4px 12px;
  border-radius: 8px;
}
.SearchResultItem-actions-status-pending {
  background-color: var(--blue-light);
}
.SearchResultItem-actions-status-sent {
  background-color: var(--green);
}
.SearchResultItem-actions-status-seen {
  background-color: var(--green);
}
.SearchResultItem-actions-status-error {
  background-color: var(--red);
}
.SearchResultItem-actions-status-removed {
  background-color: var(--grey);
}

.small-counts {
  background: var(--purple-lightest);
  padding: 12px;
  border-radius: var(--border-radius);
}
