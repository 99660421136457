.HowItWorksSection {
  /*  background-color: #d1c4df70;*/
}

.HowItWorksChecks {
  /*  padding: */
  font-size: var(--font-size-xl);
}
.HowItWorksChecks-item {
  display: flex;
}
.HowItWorksChecks-item-dot {
  margin-left: 32px;
  margin-right: 32px;
}

@media only screen and (max-width: 800px) {
  .HowItWorksChecks {
    padding-top: 40px;
    padding-bottom: 40px;
    font-size: var(--font-size-l);
  }
  .HowItWorksChecks-item {
    align-items: center;
    padding-left: 0px;
  }
  .HowItWorksChecks-item-dot {
    padding-bottom: 24px;
  }
}

.HowItWorksItem {
  /*  background-image: "url('how-it-works-box.svg')";*/
  @media only screen and (min-width: 800px) {
    max-width: 300px;
  }
  min-height: 100%;
  /*  padding: 32px;*/

  border-radius: 6px;
  /*  box-shadow: 0px 5px 10px 3px #73628a40;*/

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-left: 24px;
  margin-top: 24px;
  padding: 24px;
}

.HowItWorksItem:last-child {
  margin-left: 0px;
}

.HowItWorksItem-header {
  font-size: var(--font-size-xxl);
  font-weight: bold;
  color: var(--purple);
  padding-left: 24px;
  padding-right: 24px;
  @media only screen and (min-width: 800px) {
    padding-bottom: 32px;
  }
}

.HowItWorksItem-body {
  @media only screen and (min-width: 800px) {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 40px;
  }
  flex: 1;
  font-size: var(--font-size-xl);
  @media only screen and (min-width: 800px) {
    font-size: var(--font-size-l);
  }
  font-weight: bold;
}

.HowItWorksItem-image {
  /*  max-width: 50%;*/
  height: 100px;
  @media only screen and (min-width: 800px) {
    height: 200px;
    margin-bottom: 40px;
    margin-top: 40px;
  }
}

@media only screen and (max-width: 800px) {
  .HowItWorksItem {
    margin-left: 0px;
    margin-bottom: 24px;
  }
}
