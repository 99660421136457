.create-event-image {
  height: 300px;
}

.EventsSection {
  margin: 0 var(--page-h-padding);
  border: 2px solid var(--purple-dark);
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 800px) {
    border: none;
    margin: 0;
  }
}
.EventsGrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 40px;
}

.styleBox {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background-color: grey;
}

.buttonBox {
  display: flex;
  flex-direction: column;
  /*  justify-content: space-between;*/
  padding: 40px 60px;
  /*  width: 670px;*/
  /*  height: 370px;*/
  /*  min-height: 100px;*/

  height: max-content;
}
.create-event-title {
  font-size: 32px;
}
.create-event-logo {
  height: 72px;
}

.camera-icon {
  height: 40px;
}

.EventItem {
  text-align: center;
  padding: 32px;
  width: 200px;
  height: 200px;
  margin-left: 24px;
  margin-bottom: 24px;
  border-radius: 8px;
  color: black;
  border: 2px solid var(--primary-backgound-color);
  background-color: #ffffff;

  @media only screen and (max-width: 800px) {
    width: 100%;
    margin-left: 0px;
  }
}

.EventItem:hover {
  border: 2px solid var(--purple-dark);
}

.event-item-image {
  height: 80px;
}

.events-filter {
  height: 44px;
  width: 440px;
  box-shadow: 0px 5px 10px 3px #73628a40;
  padding-bottom: 24px;
  display: flex;
  align-items: center;
}
