.checkbox-container {
  cursor: pointer;
}

.checkbox-container input {
  display: none;
}

.custom-checkbox {
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 5px;
  border: 2px solid var(--purple-dark);
  background-color: #fff;
}

.checkmark {
  display: none;
  position: absolute;
  top: 10%;
  left: 10%;
  height: 12px;
  width: 7px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform-origin: bottom;
  transform: rotate(45deg);
}

.custom-checkbox.checked {
  background-color: var(--purple);
}

.custom-checkbox.checked .checkmark {
  display: block;
}
