.UserGeneralInfo {
  max-width: 50%;
}
.user-details {
  border: 2px solid var(--purple-dark);
}
.UserCardForm {
  border: 2px solid var(--purple-dark);
  @media only screen and (min-width: 800px) {
    flex: 2;
    margin-right: 40px;
  }
}

.card-preview-wrapper {
  background: white;
  border-radius: var(--border-radius);
  border: 2px solid var(--purple-dark);
  padding: 32px;

  margin-bottom: 32px;
}
.card-preview-wrapper-header {
  font-size: var(--font-size-xxl);
  color: var(--border-radius);
  text-align: center;
  padding-bottom: 32px;
}

.user-card {
  background: var(--purple-lightest);
  border-radius: var(--border-radius);
  border: 2px solid var(--purple-dark);
}
