.popup-close-icon {
  position: absolute;
  left: 16px;
  top: 16px;
}
.popup-content {
  position: relative;
  border: 2px solid var(--purple-dark);
  padding: 60px 48px;
  border-radius: 24px;

  max-height: 80%;
  overflow: auto;

  @media only screen and (max-width: 800px) {
    width: 80%;
  }
  width: 44%;
}
.small-popup-content {
  @media only screen and (max-width: 800px) {
    width: 80%;
  }
  width: 25%;
}

/* ShareEventPopupButton */
.ShareEventPopupButton-link {
  font-size: var(--font-size-l);
  color: var(--purple-dark);
  background-color: var(--purple-lightest);
  padding: 24px 28px;
  border-radius: 14px;
}
