.toggle-container {
  position: relative;
}

.toggle-label {
  display: block;
  min-width: 60px;
  height: 30px;
  background-color: var(--purple-light); /* Purple background color */
  border-radius: 15px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;

  @media only screen and (max-width: 800px) {
    min-width: 40px;
    max-width: 40px;
    height: 20px;
  }
}

.toggle-handle {
  width: 30px;
  height: 30px;
  @media only screen and (max-width: 800px) {
    width: 20px;
    height: 20px;
  }
  background-color: #fff; /* White handle color */
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease;
}

input[type="checkbox"]:checked + .toggle-label {
  background-color: var(--purple);
}

input[type="checkbox"]:checked + .toggle-label .toggle-handle {
  transform: translateX(30px);
  @media only screen and (max-width: 800px) {
    transform: translateX(20px);
  }
}
