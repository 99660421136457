.step-wrapper {
  flex: 1;

  padding: 24px;
  background-color: var(--pane-backgound-color);
  margin: 0 32px;
  border-radius: var(--pane--border-radius);
  /*  height: 720px;*/
  /*  width: 60%;*/

  display: flex;
  flex-direction: column;
  align-items: center;
}
.grey-pane {
  padding: 24px;
  background-color: var(--grey-pane-backgound-color);
  border-radius: var(--pane--border-radius);
}

.step-page-image {
  height: 120px;
}
.whatsapp-icon {
  height: 24px;
  margin-left: 3px;
}
.link-input {
  width: 400px;
}

.done-circle {
  border: 4px solid var(--primary-color);
  border-radius: 50%;
  width: 40px;
  height: 36px;
}

.run-badge-icon {
  font-size: 36px;
}

.status-pane {
  background-color: var(--grey-pane-backgound-color);
  border-radius: var(--pane--border-radius);
  padding: 24px;
  overflow-y: auto;
}
.StatusHeader {
  width: 100%;
  text-align: right;
  display: grid;
  grid-template-columns: 1fr 3fr;
}
.StatusTable {
  width: 100%;
  text-align: right;
  display: grid;
  /*  grid-template-columns: 1fr 3fr;*/
  /*  overflow-y: auto;*/
  max-height: 500px;
}
.StatusTableRow {
  display: flex;
}

.error-cross {
  margin: auto;
  border: 3px solid red;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: red;
  font-weight: bold;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.horizontal-line-divider {
  border-radius: 5px;
  height: 5px;
  width: 100%;
  background-color: #80808080;
}

.EventDetailsBoxesContainer {
  border-radius: 14px;
  border: 2px solid var(--purple-dark);
}

.EventDetailsGrid-FirstRow {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  @media only screen and (max-width: 800px) {
    grid-template-columns: 1fr;
  }
}

.EventDetailsGrid-SecondRow {
  display: grid;
  grid-template-columns: 5fr 3fr;
  @media only screen and (max-width: 800px) {
    grid-template-columns: 1fr;
  }
}

.EventGrid-LeftBorder {
  border-left: 2px solid var(--purple-dark);
  @media only screen and (max-width: 800px) {
    border-left: none;
    border-bottom: 2px solid var(--purple-dark);
  }
}
.EventGrid-TopBorder {
  border-top: 2px solid var(--purple-dark);
}
.EventDetailsBox {
  padding: 64px;
  @media only screen and (max-width: 800px) {
    padding: 40px 20px;
  }
}

@media only screen and (min-width: 800px) {
  .EventDetailsBox {
    /*    width: var(--event-box-max-width);*/
  }
}
