.ContactUs-section {
  justify-content: space-between;
  align-items: center;
  padding: 0px var(--page-h-padding-big);

  font-size: var(--font-size-l);
  background-color: var(--purple-lightest);
}

/*.ContactUs-section*/

.ContactUs-line {
  /*width: 100%;*/

  display: flex;
  align-items: center;
  padding-bottom: 24px;
}

.ContactUs-logo {
  height: 88px;
}
@media only screen and (max-width: 800px) {
  .ContactUs-logo {
    max-height: 160px;
  }

  .ContactUs-section {
    padding: 0;
  }
  .ContactUs-line {
    padding-right: 32px;
  }
}
