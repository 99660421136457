.EventSettings-AutoSent {
  display: inline-flex;
  align-items: flex-start;
  border: 1px solid var(--purple-lightest);
  border-radius: 10px;
  background-color: var(--purple-lightest);
  padding: 16px;

  @media only screen and (max-width: 800px) {
    border: 1px solid var(--purple);
  }
}
.EventSettings-AutoSent:hover {
  border: 1px solid var(--purple);
}
