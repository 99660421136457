.BroadcastEventStatus {
  display: flex;
  justify-content: space-between;

  border-radius: var(--border-radius);
  border: 1px solid var(--purple-dark);
}
.BroadcastEventStatusItem {
  padding: 4px 16px;
  border-left: 1px solid var(--purple-dark);
  display: flex;
  justify-content: center;
  width: 100%;
}

.BroadcastEventStatusItem:last-child {
  border-left: none;
}
