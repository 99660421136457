.menu-popup-content {
  margin-right: 100px !important;
  padding: 0 !important;
  width: 300px !important;
  border-radius: 12px !important;
}

.NavMenu-trigger {
}

.NavMenu-item {
  display: flex;
  align-items: center;

  cursor: pointer;
  padding: 32px;
  border-bottom: 1px solid var(--purple);
}
.NavMenu-item:nth-last-child(2) {
  /*padding-bottom: 40px;*/
}

.NavMenu-item:hover {
  background-color: var(--purple-lightest);
}

.NavMenu-header {
  display: flex;
  align-items: center;
  padding: 16px;
  color: var(--purple-dark);
  background-color: var(--purple-light);
  border-bottom: 1px solid var(--purple);
}

.NavMenu-profile-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  border-bottom: 1px solid var(--purple);
}
.NavMenu-logout {
  display: flex;
  justify-content: center;
  padding: 16px;
  background-color: var(--purple-light);
  color: var(--purple-dark);
}
